<template>
    <header>
        <h1>that movie with...</h1>
    </header>
</template>

<style>
h1 {
    color: #ff0000;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.5em;
    padding-block: 24px;
    text-align: center;
}
</style>

<script>
export default {};
</script>
