<template>
    <v-footer>
        <img alt="TMDB" :src="require('../assets/tmdb.svg')">
        <em>
            This product uses the TMDB API but is not endorsed or certified by TMDB.
        </em>

        <v-spacer></v-spacer>

        <span>&copy; {{ year }}&nbsp;<a href="//ttam.co" target="_blank">Matt Bannon</a>.</span>
    </v-footer>
</template>

<style lang="scss">
.theme--light.v-footer {
  &, a {
    color: #ffffff;
  }
}

.theme--light.v-footer {
  background-color: #000000;
  flex-direction: column;
  padding-inline: 0;

  em, span {
    display: flex;
    flex: 1;
    text-align: center;
  }
}

footer img {
  margin-bottom: 5px;
  width: 80px;
}
</style>

<script>
export default {
    data: () => ({ year: (new Date()).getFullYear() })
};
</script>
