<template>
    <v-card>
        <v-responsive aspect-ratio="1">
            <v-overlay absolute>
                <search-entity @cancel="cancel" v-if="entityType !== null"></search-entity>
                <select-options v-else></select-options>
            </v-overlay>
        </v-responsive>
    </v-card>
</template>

<style lang="scss">
.divider {
  height: 2em;
}
</style>

<script>
import SearchEntity from '@/components/SearchEntity';
import SelectOptions from '@/components/SelectOptions';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: { SearchEntity, SelectOptions },

    computed: mapGetters(['entities', 'entityType']),

    methods: {
        ...mapActions(['setEntityType']),

        cancel ()
        {
            if (this.entities.length === 0) {
                this.setEntityType(null);
            }
        }
    }
};
</script>
