<template>
    <div class="align-center d-flex flex-column">
        <v-autocomplete
                @change="selectEntity"
                dense
                hide-no-data
                hide-selected
                :items="items"
                item-text="title"
                outlined
                placeholder="Search"
                :prepend-inner-icon="mdiMagnify"
                return-object
                rounded
                :search-input.sync="search"
                :value="entity"
        >
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                    <v-list-item-avatar>
                        <img :src="data.item.image">
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.title"></v-list-item-title>
                        <v-list-item-subtitle v-if="entityType === 'movie'" v-html="data.item.year"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>

        <v-spacer></v-spacer>

        <v-btn @click="$emit('cancel')" color="white" fab outlined small v-if="entities.length === 0">
            <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
    </div>
</template>

<style lang="scss">
.divider {
  height: 2em;
}

.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #ffffff;
}

.v-text-field .v-input__append-inner {
  display: none;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdiClose, mdiMagnify } from '@mdi/js';
import { searchEntity } from '@/lib/search';

export default {
    data: () => ({
        entity: null,
        items: [],
        mdiClose,
        mdiMagnify,
        search: '',
        timer: null,
    }),

    computed: mapGetters(['entities', 'entityType']),

    methods: {
        ...mapActions(['addEntity']),

        selectEntity (entity)
        {
            if (entity !== null) {
                this.entity = '';
                this.search = '';
                this.addEntity(entity);
            }
        }
    },

    watch: {
        search: function (value) {
            clearTimeout(this.timer);

            if (value === '' || value === null) {
                return;
            }

            this.timer = setTimeout(() => searchEntity(this.entityType, value).then(({ data }) => this.items = data), 150);
        },
    },
};
</script>
