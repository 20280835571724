<template>
    <v-card>
        <v-img aspect-ratio="1" :src="entity.image">
            <v-overlay absolute class="entity-name">
                <span>{{ entity.title }}</span>

                <v-spacer></v-spacer>

                <v-btn @click="removeEntity(entity.id)" fab outlined small>
                    <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
            </v-overlay>
        </v-img>
    </v-card>
</template>

<style lang="scss">
.v-overlay.entity-name {
  height: 5em;
  top: auto;
}

.entity-name .v-overlay__content {
  display: flex;
  font-size: 1.3em;
  padding-inline: 1em;
  width: 100%;

  span {
    line-height: 40px;
  }
}
</style>

<script>
import { mapActions } from 'vuex';
import { mdiClose } from '@mdi/js';

export default {
    data: () => ({ mdiClose }),

    methods: mapActions(['removeEntity']),

    props: {
        entity: { required: true, type: Object },
    }
};
</script>
