<template>
    <div class="select-options">
        <v-btn @click="setEntityType('actor')" color="primary" v-if="entityType !== 'movie'">Add an actor</v-btn>

        <div class="divider" v-if="entities.length === 0"></div>

        <v-btn @click="setEntityType('movie')" color="primary" v-if="entityType !== 'actor'">Add a film</v-btn>

        <div class="divider" v-if="entities.length === 0"></div>

        <v-menu offset-y v-if="entities.length === 0">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">Select a preset</v-btn>
            </template>

            <v-list>
                <v-list-item :key="index" link v-for="(item, index) in presets">
                    <v-divider v-if="item.divider" />
                    <v-list-item-title @click="applyPreset(item)" v-else>{{ item.text }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<style>
.select-options .v-btn {
    width: 100%;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: mapGetters(['entities', 'entityType', 'presets']),

    methods: mapActions(['applyPreset', 'setEntities', 'setEntityType']),
};
</script>
