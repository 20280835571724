<template>
    <v-row v-if="results && results.entities">
        <v-col cols="12" :key="index" :md="sharedEntityList.length < 2 ? 12 : 6" v-for="(group, index) in sharedEntityList">
            <v-simple-table class="results-table">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th colspan="2">
                                {{ getEntityNames(group.entities) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="entityIndex" v-for="(entity, entityIndex) in getShared(group.shared)">
                            <td>
                                <v-img aspect-ratio="1" contain :src="`https://image.tmdb.org/t/p/w500/${ entity.image  }`" width="75"/>
                            </td>
                            <td class="result-details text-center">
                                {{ entity.name }}
                                <small v-if="entityType === 'actor'">({{ entity.year }})</small>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<style lang="scss">
div.v-data-table {
  background: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid white;
}

thead, tr {
  display: table;
  width: 100%;
}

th {
  background: rgba(255, 255, 255, 0.5);
  color: #111 !important;
  font-size: 1.3em !important;
  font-weight: 600 !important;
}

tbody {
  display: block;
  max-height: 330px;
  overflow-y: auto;
}

tr {
  border-bottom: 1px solid white;

  &:hover { background-color: inherit !important; }
}

td {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 1.3em !important;
  text-shadow: -1px -1px 0 #323232, 1px -1px 0 #323232, -1px 1px 0 #323232, 1px 1px 0 #323232;

  &:first-child {
    width: 100px;
  }
}

small {
  font-weight: 100;
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['entities', 'entityType', 'results']),

        sharedEntityList ()
        {
            return this.results.common.filter(({ shared }) => shared.length > 0);
        },
    },

    methods: {
        getEntityNames (list)
        {
            const names = list.map(i => this.entities.find(j => i === j.id)?.title);
            return names.concat(names.splice(-2, 2).join(' and ')).join(', ');
        },

        getShared (list)
        {
            return list.map(i => this.results.entities[i]);
        }
    },
};
</script>
