<template>
    <v-app>
        <site-header></site-header>

        <site-content></site-content>

        <site-footer></site-footer>
    </v-app>
</template>

<style>
.v-application {
    margin: 0 auto;
    max-width: min(80%, 1200px);
}
</style>

<script>
import SiteContent from './components/SiteContent';
import SiteFooter from './components/SiteFooter';
import SiteHeader from './components/SiteHeader';

export default {
    components: { SiteContent, SiteFooter, SiteHeader },
};
</script>
