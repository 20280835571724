import { getResults } from '@/lib/search';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        entityType: null,
        entities: [],
        presets: [],
        results: {},
    },

    getters: {
        entities: state => state.entities,
        entityType: state => state.entityType,
        presets: state => state.presets,
        results: state => state.results,
    },

    mutations: {
        addEntity: (state, entity) => state.entities.push(entity),
        setEntities: (state, entities) => state.entities = entities,
        setEntityType: (state, entityType) => state.entityType = entityType,
        setPresets: (state, presets) => state.presets = presets,
        set: (state, ready) => state.ready = ready,
        setResults: (state, results) => state.results = results,
    },

    actions: {
        addEntity: (context, entity) => {
            context.commit('addEntity', entity);
            return context.dispatch('search');
        },
        applyPreset: (context, { entityType, value }) => {
            context.dispatch('setEntityType', entityType)
                .then(() => context.dispatch('setEntities', value));
        },
        removeEntity: (context, id) => {
            context.commit('setEntities', context.getters.entities.filter(i => i.id !== id));
            if (context.getters.entities.length === 0) {
                context.commit('setEntityType', null);
            }

            return context.dispatch('search');
        },
        search: (context) => {
            context.dispatch('setResults', {}).then(() => {
                const ids = context.getters.entities.map(i => i.id);
                if (ids.length < 2) { return; }

                const map = ids.map(i => `ids[]=${i}`).join('&');
                    getResults(context.getters.entityType, map)
                    .then(({ data }) => {
                        context.commit('setResults', data);
                    });
            });
        },
        setEntities: (context, entities) => {
            if (entities.length === 0) {
                context.commit('setEntityType', null);
            }
            context.commit('setEntities', entities);
            return context.dispatch('search');
        },
        setEntityType: (context, entityType) => context.commit('setEntityType', entityType),
        setPresets: (context, presets) => context.commit('setPresets', presets),
        setResults: (context, results) => context.commit('setResults', results),
    },

    modules: {}
});
